import React from "react";
import Heading from "./components/Heading";
import SortOptions from "./components/SortOptions";
import InputPage from "./components/inputs/InputPage";
import { Container } from "semantic-ui-react";
import StaticPage from "./components/StaticPage";
import Trends from "./components/inputs/Trends";
import DisplayMessage from "./components/DisplayMessage";
import Comment from "./components/inputs/Comment";
import StatementList from "./components/statements/StatementList";

const shouldShowSortOptions = attributes =>
    (attributes.sort_content && attributes.sort_content.length > 0) ||
    (attributes.sort_order && attributes.sort_order.length > 0) ||
    (attributes.sort_view && attributes.sort_view.length > 0);

export const MainPage = ({
  attributes,
  props,
  state,
  submitInputs,
  apiRequest,
  handleStaticBack,
  selectStatement,
  setFilterValue,
  filterStatements
}) => {
  const { sections, editMode, inputPageErrors, staticPageContent } = state;

  const statementSections = sections.filter(
    section => section.statements.length > 0
  );
  const inputActionSections = sections.filter(
    section => section.inputActions.length > 0
  );
  const inputActionsForPage = inputActionSections.reduce(
    (all, section) => [...all, ...section.inputActions],
    []
  );

  const showTextFilter = !!sections.find(
    section => section.statements.length >= 12
  );

  const trendValueSections = sections.filter(
    section => section.trendValues.length > 0
  );

  const allStatements = sections
    .map(section => [
      ...section.statements,
      ...section.trendValues,
      ...section.inputActions
    ])
    .flat();

  if (staticPageContent)
    return <StaticPage content={staticPageContent} back={handleStaticBack} />;

  return (
    <>
      {(attributes.heading || attributes.sub_heading) && (
        <Heading
          heading={attributes.heading}
          sub_heading={attributes.sub_heading}
        />
      )}
      {attributes.display_message && (
        <Container>
          <DisplayMessage {...attributes.display_message} />
        </Container>
      )}
      <Container>
        {shouldShowSortOptions(attributes) && (
          <SortOptions
            {...props}
            attributeLink={attributes.link}
            handleClick={option => apiRequest(option.value)}
            content={{
              options: attributes.sort_content,
              label: attributes.sort_content_label
            }}
            order={{
              options: attributes.sort_order,
              label: attributes.sort_order_label
            }}
            view={{
              options: attributes.sort_view,
              label: attributes.sort_view_label
            }}
            showTextFilter={showTextFilter}
            handleSearchChange={(_, props) => setFilterValue(props.value)}
          />
        )}
        {statementSections.length > 0 &&
          statementSections.map((section, i) => {
            return (
              <StatementList
                key={i}
                editMode={editMode}
                selectStatement={selectStatement}
                statements={filterStatements(section.statements)}
                title={section.title}
                comment={section.comment}
              />
            );
          })}
        {inputActionSections.length > 0 && trendValueSections.length === 0 && (
          <InputPage
            key={attributes.up}
            statements={inputActionsForPage}
            submit={submitInputs}
            apiRequest={apiRequest}
            errors={inputPageErrors}
          />
        )}
        {trendValueSections.length > 0 && (
          <Trends
            statements={allStatements}
            submit={submitInputs}
            apiRequest={apiRequest}
            errors={inputPageErrors}
          />
        )}
      </Container>
      {attributes.comment && (
        <Container style={{ marginTop: "1rem" }}>
          <Comment content={attributes.comment} />
        </Container>
      )}
    </>
  );
};
