import { toast } from "react-semantic-toasts";

const types = {
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info"
};

const times = {
  ERROR: 5000,
  INFO: 4000,
  SUCCESS: 3000
};

export const successfulDeleteToast = displayMessage =>
  toast({
    type: displayMessage ? displayMessage.level : types.SUCCESS,
    title: displayMessage ? displayMessage.title : "Success",
    time: times.SUCCESS,
    description: displayMessage ? undefined : "Activity deleted"
  });

export const successfulEmailToast = displayMessage =>
  toast({
    type: displayMessage ? displayMessage.level : types.SUCCESS,
    title: displayMessage ? displayMessage.title : "Success",
    time: times.SUCCESS,
    description: displayMessage
      ? undefined
      : "The report has been emailed to you"
  });

export const successfulPostToast = displayMessage =>
  toast({
    type: displayMessage ? displayMessage.level : types.SUCCESS,
    title: displayMessage ? displayMessage.title : "Success",
    time: times.SUCCESS,
    description: displayMessage ? undefined : "Your changes have been saved"
  });

export const successfulInviteToast = displayMessage =>
  toast({
    type: displayMessage ? displayMessage.level : types.SUCCESS,
    title: displayMessage ? displayMessage.title : "Success",
    time: times.SUCCESS,
    description: displayMessage ? undefined : "Your invite has been sent"
  });

export const successfulForgotPasswordToast = displayMessage =>
  toast({
    type: displayMessage ? displayMessage.level : types.SUCCESS,
    title: displayMessage ? displayMessage.title : "Email sent!",
    time: times.SUCCESS,
    description: displayMessage
      ? undefined
      : "Please check your emails for the link"
  });

export const successfulResetPasswordToast = displayMessage =>
  toast({
    type: displayMessage ? displayMessage.level : types.SUCCESS,
    title: displayMessage ? displayMessage.title : "Password reset!",
    time: times.SUCCESS,
    description: displayMessage
      ? undefined
      : "Please use your new password to log in"
  });

export const successfulSignupToast = displayMessage =>
  toast({
    type: displayMessage ? displayMessage.level : types.SUCCESS,
    title: displayMessage ? displayMessage.title : "Registration successful!",
    time: times.SUCCESS,
    description: displayMessage
      ? undefined
      : "Please use your details to log in"
  });

const errorDescription = response => {
  return (
    "Something went wrong: " +
    (response.status ||
    response.code ||
    (response.error && response.error.status_code)
      ? `. Code: ${response.status ||
          response.code ||
          (response.error && response.error.status_code)}`
      : "")
  );
};

export const errorToast = response =>
  toast({
    type: types.ERROR,
    title: "Sorry",
    time: times.ERROR,
    description: errorDescription(response)
  });

export const infoToast = message =>
  toast({
    type: types.INFO,
    time: times.INFO,
    description: message
  });
