import React from "react";
// import { Segment, Form } from 'semantic-ui-react'
import "./DropdownDatePicker.css";
import { months } from "../../config/copy";

const getDaysInMonth = (month, year = new Date().getFullYear()) =>
  new Date(year, month, 0).getDate();

const getDaysInMonthArray = (month, year = new Date().getFullYear()) =>
  Array.from(Array(getDaysInMonth(month, year)).keys()).map(i => i + 1);

const getYearsArray = () =>
  Array.from(Array(10).keys()).map(
    year => -year + new Date().getFullYear() + 2
  );

const dateStringToObj = string => {
  if (!string)
    return {
      dayNum: "",
      monthNum: "",
      yearNum: ""
    };

  const splitDateString = string.split("/");
  return {
    dayNum: parseInt(splitDateString[0], 10),
    monthNum: parseInt(splitDateString[1], 10),
    yearNum: parseInt(splitDateString[2], 10)
  };
};

class DropdownDatePicker extends React.Component {
  state = {
    monthNum: "",
    dayNum: "",
    yearNum: ""
  };

  componentDidMount() {
    const { value } = this.props;
    this.setState(dateStringToObj(value));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== "" && this.props.value === "") {
      this.setState({
        monthNum: "",
        dayNum: "",
        yearNum: ""
      });
    } else if (prevProps.value !== this.props.value) {
      const { value } = this.props;
      this.setState(dateStringToObj(value));
    }
  }

  isDateComplete = () =>
    this.state.dayNum !== "" &&
    this.state.monthNum !== "" &&
    this.state.yearNum !== "";

  handleChange = (key, selectedValue) => {
    this.setState(
      { [key]: parseInt(selectedValue, 10) },
      () =>
        this.isDateComplete() &&
        this.props.onChange(null, {
          value: `${this.state.dayNum
            .toString()
            .padStart(2, "0")}/${this.state.monthNum
            .toString()
            .padStart(2, "0")}/${this.state.yearNum}`
        })
    );
  };

  render = () => {
    const { dayNum, monthNum, yearNum } = this.state;
    const days = monthNum ? getDaysInMonthArray(monthNum, yearNum) : [];

    const years = getYearsArray();

    return (
      <div className="dropdownDatePickerContainer">
        <select
          key="month"
          onChange={e =>
            this.handleChange("monthNum", parseInt(e.target.value, 10))
          }
          value={monthNum}
        >
          <option key="default" value="" disabled>
            Select month
          </option>
          {months.map((month, i) => (
            <option key={i} value={i + 1}>
              {month}
            </option>
          ))}
        </select>

        <select
          key="day"
          onChange={e =>
            this.handleChange("dayNum", parseInt(e.target.value, 10))
          }
          value={dayNum}
        >
          <option key="default" value="" disabled>
            Select day
          </option>
          {days.map((day, i) => (
            <option key={i} value={i + 1}>
              {day}
            </option>
          ))}
        </select>

        <select
          key="year"
          onChange={e =>
            this.handleChange("yearNum", parseInt(e.target.value, 10))
          }
          value={yearNum}
        >
          <option key="default" value="" disabled>
            Select year
          </option>
          {years.map((year, i) => (
            <option key={i} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    );
  };
}

export default DropdownDatePicker;
