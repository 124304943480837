import React, { Component } from "react";
import {
  Form,
  Button,
  Message,
  Header,
  Container,
  Icon
} from "semantic-ui-react";
import {
  emailPlaceholder,
  passwordPlaceholder,
  signInHeader,
  signInButton,
  forgottenYourPassword
} from "../../config/copy";
import { Link } from "react-router-dom";

class Login extends Component {
  state = {
    email: "",
    password: ""
  };

  submit = e => {
    e.preventDefault();
    this.props.handleSubmit(this.state);
  };

  parseErrorContent(message) {
    if (typeof message === "string") return message;
    return Object.values(message).map(messages =>
      messages.map(m => <div key={m}>{m}</div>)
    );
  }

  render() {
    const { error, clearErrors } = this.props;

    return (
      <>
        <Container id="logo" text textAlign="center">
          <Icon
            bordered
            name="circle"
            size="huge"
            style={{ color: "#008000" }}
          />
          <Icon
            bordered
            name="circle"
            size="huge"
            style={{ color: "#FFC857" }}
          />
          <Icon bordered name="circle" size="huge" style={{ color: "red" }} />
        </Container>
        <Header as="h2">{signInHeader}</Header>
        <Form error={!!error} onSubmit={this.submit}>
          <Form.Input
            label="Email address"
            type="email"
            name="email"
            placeholder={emailPlaceholder}
            onChange={e => this.setState({ email: e.target.value })}
            input={{ autoComplete: "email" }}
          />
          <Form.Input
            label="Password"
            type="password"
            name="password"
            placeholder={passwordPlaceholder}
            onChange={e => this.setState({ password: e.target.value })}
            input={{ autoComplete: "current-password" }}
          />
          {error && (
            <Message error>{this.parseErrorContent(error.message)}</Message>
          )}
          <Button primary type="submit">
            {signInButton}
          </Button>
          <p>
            <Link to="/auth/forgot-password" onClick={clearErrors}>
              {forgottenYourPassword}
            </Link>
          </p>
        </Form>
      </>
    );
  }
}

export default Login;
