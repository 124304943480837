import React from "react";
import Trend from "react-trend";
import SubmitButtons from "./SubmitButtons";

const Trends = ({ submit, apiRequest, statements }) => {
  const submitStatement = statements.find(s => s.type === "submit_buttons");

  const trendStatement = statements.find(s => s.type === "trend_values");

  const trendValues = trendStatement.attributes.trend
    .filter(t => t.value !== null)
    .map(t => t.value);
  const trendKeys = trendStatement.attributes.trend
    .filter(t => t.value !== null)
    .map(t => t.text);

  while (trendValues.length <= 1) {
    trendValues.push(trendValues[0] || 0);
    trendKeys.push(trendKeys[0] || "");
  }

  const min = Math.min(...trendValues);
  const max = Math.max(...trendValues);

  return (
    <>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ marginTop: "0.75rem" }}>{max}</div>
          <div style={{ marginTop: "auto", marginBottom: "0.75rem" }}>
            {min}
          </div>
        </div>
        <Trend
          smooth
          autoDraw
          autoDrawDuration={500}
          autoDrawEasing="ease-out"
          gradient={["#444444", "#333333"]}
          radius={5}
          strokeWidth={2.5}
          strokeLinecap={"round"}
          data={trendValues}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingLeft: "1rem"
        }}
      >
        {trendKeys.map(key => (
          <div className="trend-key" key={key}>
            <span>{key}</span>
          </div>
        ))}
      </div>

      <SubmitButtons
        key={"buttons"}
        loading={false}
        handleClick={(url, type) => {
          type === "post" ? submit({}, url) : apiRequest(url);
        }}
        {...submitStatement.attributes}
        submitButtonShouldBeDisabled={false}
      />
    </>
  );
};

export default Trends;
