import "url-search-params-polyfill";
import "whatwg-fetch";
import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_MODE !== "dev") {
  const noop = () => {};
  console.log = noop;
}

Sentry.init({
  dsn:
    "https://019940d36ee2e0284bc475b31f7c12b0@o4507731883655168.ingest.de.sentry.io/4507731885817936",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    /^https:\/\/trialtools\.clearlysimpler\.co\.uk/,
    /^https:\/\/tools\.parishdashboards\.org\.uk/
  ]
});

ReactDOM.render(
  <Router>
    <Route path="/" component={App} />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
