import React from "react";
import { Segment } from "semantic-ui-react";
import "./Comment.css";

const Comment = ({ content, color }) => {
  const customisedContent = content.replace(
    "<table",
    '<table class="ui table celled striped"'
  );

  return (
    <Segment
      className={(color === null) || (color === undefined) || (color === '') ? "bg color2" : color}
      dangerouslySetInnerHTML={{ __html: customisedContent }}
    />
  );
};

export default Comment;
