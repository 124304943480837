import React from "react";
import { Button, Container } from "semantic-ui-react";

const StaticPage = ({ content, back }) => {
  return (
    <Container text>
      {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
      <Button onClick={back} primary>
        Go back
      </Button>
    </Container>
  );
};

export default StaticPage;
