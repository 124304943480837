import React from "react";
import { Button, Input } from "semantic-ui-react";
import { textFilterPlaceholder } from "../config/copy";
import "./SortOptions.css";

const button = (handleClick, locations) => option => {
  const active = locations.includes(option.value) || option.selected;

  return (
    <Button
      className="bg color3 color1"
      active={active}
      onClick={!active ? () => handleClick(option) : undefined}
      key={option.text}
      compact
      basic
      style={{ cursor: active ? "auto" : "pointer" }}
    >
      {option.text}
    </Button>
  );
};

const SortOptions = ({
  content,
  order,
  view,
  handleClick,
  location,
  attributeLink,
  showTextFilter,
  handleSearchChange
}) => {
  order.options = order.options || [];
  content.options = content.options || [];
  view.options = view.options || [];
  const singleSet =
    order.options.length === 0 ||
    content.options.length === 0 ||
    view.options.length === 0;

  return (
    <div
      id="sort-options"
      className={`${showTextFilter ? "filter" : ""} ${
        singleSet ? "single" : "double"
      }`}
    >
      {content.options.length > 0 && (
        <Button.Group>
          {content.options.length > 0 && (
            <>
              <Button
                className="bg color3 color1"
                compact
                basic
                icon="tag"
                style={{ cursor: "auto" }}
              />
              {content.options.map(
                button(handleClick, [location.pathname, attributeLink])
              )}
            </>
          )}
        </Button.Group>
      )}
      {order.options.length > 0 && (
        <Button.Group>
          {order.options.length > 0 && (
            <>
              <Button
                className="bg color3 color1"
                compact
                basic
                icon="sort"
                style={{ cursor: "auto" }}
              />
              {order.options.map(
                button(handleClick, [location.pathname, attributeLink])
              )}
            </>
          )}
        </Button.Group>
      )}
      {view.options.length > 0 && (
        <Button.Group>
          {view.options.length > 0 && (
            <>
              <Button
                className="bg color3 color1"
                compact
                basic
                icon="eye"
                style={{ cursor: "auto" }}
              />
              {view.options.map(
                button(handleClick, [location.pathname, attributeLink])
              )}
            </>
          )}
        </Button.Group>
      )}
      {showTextFilter && (
        <Input
          size="small"
          icon="search"
          placeholder={textFilterPlaceholder}
          onChange={handleSearchChange}
        />
      )}
    </div>
  );
};

export default SortOptions;
