import React, { Component } from "react";
import { Menu, Button, Container, Header, Responsive } from "semantic-ui-react";
import { desktopScreenWidth } from "../config/sizes";
import "./TopBar.css";

class TopBar extends Component {
  state = {
    homeLoading: false,
    upLoading: false
  };

  content = () => {
    const { menu } = this.props;
    const { homeLoading, upLoading } = this.state;

    const up = () => {
      this.setState({ upLoading: true });
      this.props.up &&
        this.props.up().then(() => this.setState({ upLoading: false }));
    };
    const home = () => {
      this.setState({ homeLoading: true });
      this.props.home &&
        this.props.home().then(() => this.setState({ homeLoading: false }));
    };
  const isHome = (this.props.url && (this.props.url === '/api/v1/'));

    return (
      <Container as={Menu} inverted>
        <Responsive
          as={Menu.Item}
          minWidth={desktopScreenWidth + 1}
          className="horizontal compact"
        >
          <Button
            onMouseDown={e => e.preventDefault()}
            loading={homeLoading}
            disabled={homeLoading}
            style={{ visibility: !this.props.home ? "hidden" : "inherit" }}
            onClick={home}
            size="big"
            inverted
            icon={(isHome) ? "list" : "home"}
          />
        </Responsive>
        <Responsive
          as={Menu.Item}
          maxWidth={desktopScreenWidth}
          className="horizontal compact"
        >
          <Button
            onMouseDown={e => e.preventDefault()}
            onClick={menu}
            size="big"
            inverted
            icon="sidebar"
          />
        </Responsive>
        <Menu.Item
          className="horizontal compact"
          style={{ margin: "auto", flexDirection: "column", maxWidth: "68vw" }}
        >
          <Header
            textAlign="center"
            style={{ marginBottom: 0 }}
            inverted
            as="h3"
          >
            {this.props.title}
          </Header>
          {this.props.sub_title && this.props.sub_title.length > 0 && (
            <Header
              textAlign="center"
              style={{ marginTop: 0 }}
              inverted
              as="h4"
            >
              {this.props.sub_title}
            </Header>
          )}
        </Menu.Item>
        <Menu.Item className="horizontal compact">
          <Button
            onMouseDown={e => e.preventDefault()}
            loading={upLoading}
            disabled={upLoading}
            style={{ visibility: !this.props.up ? "hidden" : "inherit" }}
            size="big"
            inverted
            icon="chevron left"
            onClick={up}
          />
        </Menu.Item>
      </Container>
    );
  };

  render() {
    return (
      <>
        <div id="top-bar" className="bg color1">
          {this.content()}
        </div>
        <div id="top-bar-hidden">{this.content()}</div>
      </>
    );
  }
}

export default TopBar;
