import React, { Component } from "react";
import Login from "./Login";
import API from "../../adapters/API";
import { Route } from "react-router-dom";
import { Container } from "semantic-ui-react";
import "./Authentication.css";
import { infoToast } from "../../config/toasts";
import {
  signUpFields,
  resetFields,
  forgotFields
} from "../../config/authFields";
import ValidFieldForm from "./ValidFieldForm";
import StaticPage from "../StaticPage";
import {
  signUpHeader,
  signUpButton,
  resetPasswordHeader,
  resetPasswordButton,
  forgottenPasswordHeader,
  forgottenPasswordButton,
  forgottenPasswordBody
} from "../../config/copy";

class Authentication extends Component {
  state = {
    error: undefined,
    signUpAttributes: {},
    prefilledFields: {}
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    if (
      ["/auth/signup", "/auth/decline", "/auth/declineall"].includes(
        this.props.location.pathname
      ) &&
      urlParams.get("token")
    ) {
      this.checkSignupToken(
        urlParams.get("token"),
        urlParams.get("em"),
        this.props.location.pathname
      );
      if (urlParams.get("em")) {
        this.setState({
          prefilledFields: {
            ...this.state.prefilledFields,
            email: urlParams.get("em")
          }
        });
      }
    } else if (
      this.props.location.pathname === "/auth/reset-password" &&
      urlParams.get("code")
    ) {
      this.setState({
        resetCode: urlParams.get("code")
      });
    }
  }

  checkSignupToken = (token, email, pathname) => {
    const splitPathname = pathname.split("/");
    const checkType = splitPathname[splitPathname.length - 1];
    API.checkSignUpToken(token, email, checkType)
      .then(status => {
        if (status.error) {
          if (status.error.message) {
            infoToast(status.error.message);
          }
          if (
            status.error.status_code === 418 ||
            status.error.status_code === 302
          ) {
            this.props.history.push(
              API.getToken() ? "/signout" : "/auth/login"
            );
          }
        } else {
          this.setState({
            token,
            signUpAttributes: { ...status.data.attributes }
          });
        }
      })
      .catch(error => {
        if (error.message === "Network request failed") {
          infoToast("You have been added to that dashboard.");
          this.props.history.push("/auth/login/");
        }
      });
  };

  handleForgotSubmit = userDetails =>
    API.forgotPassword(userDetails).then(res => {
      if (res.code && res.error) {
        this.setState({ error: undefined });
        this.props.handleApiError(res);
      } else if (res.error) {
        this.setState({ error: res.error });
      } else {
        this.setState({ error: undefined });
        this.props.handleForgotSuccess(res);
      }
    });

  handleResetSubmit = userDetails =>
    API.resetPassword(userDetails, this.state.resetCode).then(res => {
      if (res.code && res.error) {
        this.setState({ error: undefined });
        this.props.handleApiError(res);
      } else if (res.error) {
        this.setState({ error: res.error });
      } else {
        this.setState({ error: undefined });
        this.props.handleResetSuccess(res);
      }
    });

  handleLoginSubmit = userDetails =>
    API.login(userDetails).then(res => {
      if (res.error) {
        this.setState({ error: res.error });
      } else {
        this.setState({ error: undefined });
        this.props.handleLoginSuccess(res);
      }
    });

  handleSignupSubmit = userDetails =>
    API.signup({ ...userDetails, token: this.state.token }).then(res => {
      if (res.error) {
        this.setState({ error: res.error });
      } else {
        this.setState({ error: undefined });
        this.props.handleSignupSuccess(res);
      }
    });

  displayStaticPage = url =>
    API.getUrl(url).then(({ staticPageContent }) => {
      this.setState({
        staticPageContent
      });
    });

  render() {
    const { error, signUpAttributes, staticPageContent } = this.state;

    return (
      <Container text>
        <Route
          exact
          path="/auth/forgot-password"
          render={() => (
            <ValidFieldForm
              fields={forgotFields}
              headerCopy={forgottenPasswordHeader}
              buttonCopy={forgottenPasswordButton}
              error={error}
              handleSubmit={this.handleForgotSubmit}
              intro={forgottenPasswordBody}
            />
          )}
        />
        <Route
          exact
          path="/auth/reset-password"
          render={() => (
            <ValidFieldForm
              fields={resetFields}
              headerCopy={resetPasswordHeader}
              buttonCopy={resetPasswordButton}
              error={error}
              handleSubmit={this.handleResetSubmit}
              intro={signUpAttributes.intro}
            />
          )}
        />
        <Route
          exact
          path="/auth/login"
          render={() => (
            <Login
              error={error}
              handleSubmit={this.handleLoginSubmit}
              clearErrors={() => this.setState({ error: undefined })}
            />
          )}
        />
        <Route
          exact
          path="/auth/signup"
          render={() => (
            <>
              {staticPageContent && (
                <StaticPage
                  content={staticPageContent}
                  back={() => this.setState({ staticPageContent: undefined })}
                />
              )}
              <ValidFieldForm
                styles={{ display: staticPageContent ? "none" : "block" }}
                fields={signUpFields.map(field => {
                  if (!this.state.prefilledFields[field.name]) return field;
                  return {
                    ...field,
                    value: this.state.prefilledFields[field.name]
                  };
                })}
                headerCopy={signUpHeader}
                buttonCopy={signUpButton}
                error={error}
                handleSubmit={this.handleSignupSubmit}
                intro={signUpAttributes.intro}
                displayStaticPage={() =>
                  this.displayStaticPage(API.staticPageUrls.privacy)
                }
              />
            </>
          )}
        />
      </Container>
    );
  }
}

export default Authentication;
