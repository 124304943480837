import React, { Component } from "react";
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import API from "./adapters/API";
import Colors from "./adapters/Colors";
import { Route, Switch } from "react-router-dom";
import Authentication from "./components/authentication/Authentication";
import TopBar from "./components/TopBar";
import { Sidebar } from "semantic-ui-react";
import SideMenu from "./components/SideMenu";
import Confirm from "./components/Confirm";
import MaintenancePage from "./components/MaintenancePage";
import { SemanticToastContainer } from "react-semantic-toasts";
import * as Toasts from "./config/toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import {
  confirmDefault,
  confirmDelete,
  confirmSendActionPlan,
  siteName
} from "./config/copy";
import { desktopScreenWidth, sideMenuWidth } from "./config/sizes";
import { MainPage } from "./MainPage";
import { isInputAction, isStatement, isTrendValue } from "./statementTypes";

const defaultState = {
  sections: [],
  attributes: undefined,
  confirm: { open: false, loading: false, copy: confirmDefault },
  editMode: false,
  filterValue: ""
};

class App extends Component {
  state = {
    ...defaultState,
    sideBarVisible: document.documentElement.clientWidth > desktopScreenWidth,
    sidebarPusherStyle: {}
  };

  componentDidMount() {
    this.updateSidebarPusherStyle();
    window.addEventListener("resize", this.updateSidebarPusherStyle);

    if (this.maintenanceMode()) return;

    this.initialRedirectionLogic();
  }

  initialRedirectionLogic = () => {
    if (this.props.location.search) {
      this.parseUrlParams(new URLSearchParams(this.props.location.search));
    } else if (
      API.getToken() ||
      API.isStaticUrl(this.props.location.pathname)
    ) {
      if (API.isStaticUrl(this.props.location.pathname)) {
        this.getAttributes();
      }
      this.apiRequest(
        this.props.location.pathname.length > 1 &&
          !this.props.location.pathname.includes("auth")
          ? this.props.location.pathname
          : "/api/v1"
      );
    } else if (this.props.location.pathname !== "/auth/forgot-password") {
      this.props.history.push("/auth/login" + this.getNextLocationQuery());
    }
  };

  getNextLocationQuery = () => {
    const query =
      this.props.location.pathname.length > 1 &&
      !this.props.location.pathname.includes("auth")
        ? `?next=${this.props.location.pathname}`
        : "";
    return query;
  };

  componentDidUpdate(prev) {
    if (this.props.location.pathname === "/signout") {
      return this.signOut();
    }
    if (
      (this.props.history.action === "POP" ||
        this.props.history.action === "REPLACE") &&
      this.props.location.pathname.length > 1 &&
      this.props.location.pathname.includes("api")
    ) {
      return this.apiRequest(this.props.location.pathname);
    }
    if (this.props.location.pathname === "/") {
      return this.initialRedirectionLogic();
    }
  }

  getAttributes = () => {
    API.getUrl("/api/v1/").then(response => {
      if (response.data && response.data.attributes) {
        this.setState({
          attributes: this.parseAttributes(response)
        });
      }
    });
  };

  maintenanceMode = () => this.props.location.pathname.includes("maintenance");

  parseUrlParams = params => {
    if (["signup", "decline", "declineall"].includes(params.get("m"))) {
      this.props.history.push({
        pathname: `/auth/${params.get("m")}`,
        search: `?token=${params.get("token")}${
          params.get("em") ? `&em=${params.get("em")}` : ""
        }`
      });
    } else if (params.get("m") === "reset") {
      this.props.history.push({
        pathname: "/auth/reset-password",
        search: `?code=${params.get("code")}`
      });
    } else if (params.get("req")) {
      this.apiRequest(params.get("req"));
    }
  };

  handleSidebarHide = () =>
    this.setState({ sideBarVisible: this.state.wideScreen || false });

  handleMenuButtonClick = () =>
    this.setState({ sideBarVisible: !this.state.sideBarVisible });

  handleAPIResponse = response => {
    if (response.error) return this.handleApiError(response);
    if (response.token) API.setToken(response.token);
    if (!response.data && !response.staticPageContent)
      return this.handleApiError(response);
    if (response.staticPageContent)
      return this.setState({
        staticPageContent: response.staticPageContent
      });

    Colors.update(response.data.attributes.colors);
    this.setState({
      staticPageContent: response.staticPageContent,
      inputPageErrors: undefined,
      sections: this.parseSections(response),
      attributes: this.parseAttributes(response),
      filterValue: ""
    });
    document.title =
      response.data.attributes.title || response.data.attributes.heading;
  };

  parseSections = response => {
    const sectionsWithMetadata = Object.entries(
      response.data.relationships || {}
    ).filter(([, data]) => !!data.meta);

    if (sectionsWithMetadata.length === 0)
      return [
        {
          statements: (response.included || []).filter(isStatement),
          inputActions: (response.included || []).filter(isInputAction),
          trendValues: (response.included || []).filter(isTrendValue)
        }
      ];

    return sectionsWithMetadata
      .sort(([, a], [, b]) => a.meta.groupSequence - b.meta.groupSequence)
      .map(([, data]) => {
        if (!Array.isArray(data.data)) data.data = [data.data];

        const allStatements = data.data.map(statement =>
          response.included.find(s => s.id === statement.id)
        );

        return {
          title: data.meta.groupTitle || undefined,
          comment: data.meta.groupComment || undefined,
          statements: allStatements.filter(isStatement),
          inputActions: allStatements.filter(isInputAction),
          trendValues: allStatements.filter(isTrendValue)
        };
      });
  };

  parseAttributes = response => {
    return response.data.attributes;
  };

  handleApiError = response => {
    if (response.code === 503) {
      return this.props.history.push("/maintenance/");
    }
    if (response.error && response.error.status_code === 302) {
      const options = {
        signout: this.signOut
      };
      return (options[response.error.action] || (() => {}))();
    }
    if (
      response.error &&
      response.error.status_code === 401 &&
      response.error.message === "not logged in"
    ) {
      return this.signOut();
    }
    if (response.error && response.error === "token_not_provided") {
      return this.props.history.push(
        "/auth/login" + this.getNextLocationQuery()
      );
    }
    if (
      response.error &&
      response.error.message &&
      response.error.status_code === 400
    )
      return this.setState({
        inputPageErrors: response.error.message
      });
    if (
      response.error === "token_expired" ||
      response.error === "token_invalid"
    )
      return this.signOut();
    Toasts.errorToast(response);
    this.props.history.push("/");
    this.signOut();
  };

  apiRequest = link => {
    this.props.history.push(link);
    return API.getUrl(link)
      .then(this.handleAPIResponse)
      .catch(this.handleApiError);
  };

  deleteStatement = statement => {
    this.setState({
      confirm: {
        ...this.state.confirm,
        open: true,
        loading: false,
        copy: confirmDelete(statement.attributes.title)
      }
    });

    this.handleConfirmConfirm = () => {
      this.setState({
        confirm: { ...this.state.confirm, loading: true }
      });

      API.postUrl(statement.attributes.del_link)
        .then(() => {
          Toasts.successfulDeleteToast(this.state.attributes.display_message);
          this.setState({
            confirm: { ...this.state.confirm, open: false }
          });
        })
        .catch(this.handleApiError);
    };

    this.handleConfirmCancel = () =>
      this.setState({
        confirm: { ...this.state.confirm, open: false, loading: false }
      });
  };

  sendEmail = statement => {
    this.setState({
      confirm: {
        ...this.state.confirm,
        open: true,
        loading: false,
        copy: confirmSendActionPlan()
      }
    });

    this.handleConfirmConfirm = () => {
      this.setState({
        confirm: { ...this.state.confirm, loading: true }
      });

      API.postUrl(statement.attributes.email_link)
        .then(() => {
          Toasts.successfulEmailToast(this.state.attributes.display_message);
          this.setState({
            confirm: { ...this.state.confirm, open: false }
          });
        })
        .catch(this.handleApiError);
    };

    this.handleConfirmCancel = () =>
      this.setState({
        confirm: { ...this.state.confirm, open: false, loading: false }
      });
  };

  selectStatement = statement => {
    if (statement.attributes.email_link) {
      return this.sendEmail(statement);
    }
    if (statement.attributes.action === "edit_activities") {
      this.setState({
        editMode: !this.state.editMode
      });
      return;
    }
    if (this.state.editMode && statement.attributes.del_link) {
      return this.deleteStatement(statement);
    }
    this.setState({
      sections: this.state.sections.map(section => ({
        ...section,
        statements: section.statements.map(s => {
          if (s === statement) {
            s.loading = true;
          }
          return s;
        })
      }))
    });
    return this.apiRequest(statement.attributes.link);
  };

  submitInputs = (formData, url) =>
    API.postFormTo(url, formData)
      .then(res => {
        if (res.error) return this.handleApiError(res);
        if (res.data && res.data.attributes) {
          formData.new_email !== undefined
            ? Toasts.successfulInviteToast(res.data.attributes.display_message)
            : Toasts.successfulPostToast(res.data.attributes.display_message);
        }

        return this.handleAPIResponse(res);
      })
      .catch(this.handleApiError);

  handleStaticBack = () => {
    this.setState({ staticPageContent: undefined });
    this.props.history.goBack();
  };

  setFilterValue = newFilterValue => {
    this.setState({ filterValue: newFilterValue });
  };

  filterStatements = statements => {
    if (this.state.filterValue.length === 0) return statements;

    return statements.filter(({ attributes: { title } }) =>
      title.toLowerCase().includes(this.state.filterValue.toLowerCase())
    );
  };

  updateSidebarPusherStyle = () => {
    const wideScreen =
      document.documentElement.clientWidth > desktopScreenWidth;
    this.setState({
      wideScreen,
      sideBarVisible: wideScreen,
      sidebarPusherStyle: {
        width: wideScreen ? `calc(100vw - ${sideMenuWidth})` : "",
        marginLeft: wideScreen ? sideMenuWidth : "0"
      }
    });
  };

  signOut = () => {
    API.setToken(undefined);

    this.setState(defaultState);

    this.props.history.push("/auth/login");
  };

  nextURL = () => {
    const query = new URLSearchParams(this.props.location.search);
    return query.get("next");
  };

  handleLoginSuccess = response => {
    const nextUrl = this.nextURL();
    if (nextUrl) {
      if (response.token) API.setToken(response.token);
      this.apiRequest(nextUrl);
      this.props.history.push(nextUrl);
    } else {
      this.props.history.push(this.nextURL() || "/api/v1");
      this.handleAPIResponse(response);
    }
  };

  render() {
    const {
      confirm,
      sideBarVisible,
      sidebarPusherStyle,
      wideScreen
    } = this.state;

    const attributes = this.state.attributes || { title: siteName };

    return (
      <Sidebar.Pushable style={{ minHeight: "100vh", display: "contents" }}>
        <SideMenu
          hideItems={this.maintenanceMode()}
          account={API.getToken() && "/api/v1/user/home"}
          {...attributes}
          {...API.staticPageUrls}
          signin={
            !API.getToken() &&
            (() => {
              this.props.history.push("/auth/login");
              this.handleSidebarHide();
            })
          }
          signout={API.getToken() && this.signOut}
          wideScreen={wideScreen}
          visible={sideBarVisible}
          handleSidebarHide={this.handleSidebarHide}
          handleItemClick={url => {
            this.apiRequest(url);
            this.handleSidebarHide();
          }}
        />

        <Sidebar.Pusher
          dimmed={sideBarVisible && !wideScreen}
          style={sidebarPusherStyle}
          onClick={sideBarVisible ? this.handleSidebarHide : () => {}}
        >
          <TopBar
            wideScreen={wideScreen}
            menu={this.handleMenuButtonClick}
            home={attributes.home && (() => this.apiRequest(attributes.home))}
            up={attributes.up && (() => this.apiRequest(attributes.up))}
            title={attributes.title}
            sub_title={attributes.sub_title}
            url={attributes.home}
          />
          <SemanticToastContainer position="top-center" />
          <Confirm
            {...confirm}
            onCancel={this.handleConfirmCancel}
            onConfirm={this.handleConfirmConfirm}
          />
          <Switch>
            <Route
              path={"/auth"}
              render={props => (
                <Authentication
                  {...props}
                  handleForgotSuccess={response => {
                    Toasts.successfulForgotPasswordToast(
                      attributes.display_message
                    );
                    this.props.history.push("/auth/login");
                  }}
                  handleResetSuccess={response => {
                    Toasts.successfulResetPasswordToast(
                      attributes.display_message
                    );
                    this.props.history.push("/auth/login");
                  }}
                  handleLoginSuccess={this.handleLoginSuccess}
                  handleSignupSuccess={response => {
                    Toasts.successfulSignupToast(attributes.display_message);
                    this.props.history.push("/auth/login");
                  }}
                  handleApiError={this.handleApiError}
                />
              )}
            />
            <Route
              path={"/api/"}
              render={props => (
                <MainPage
                  attributes={attributes}
                  props={props}
                  state={this.state}
                  submitInputs={this.submitInputs}
                  apiRequest={this.apiRequest}
                  handleStaticBack={this.handleStaticBack}
                  selectStatement={this.selectStatement}
                  setFilterValue={this.setFilterValue}
                  filterStatements={this.filterStatements}
                />
              )}
            />
            <Route
              path={"/maintenance/"}
              render={props => <MaintenancePage />}
            />
          </Switch>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

export default App;
