const getCSSRules = ruleName => {
  if (!document.styleSheets) return [];

  ruleName = ruleName.toLowerCase();
  const cssRules = [];

  try {
    Object.values(document.styleSheets)
      .filter(sheet => sheet.cssRules && sheet.cssRules.length > 0)
      .forEach(sheet => {
        try {
          Object.values(sheet.cssRules)
            .filter(rule => !!rule.selectorText)
            .forEach(rule => {
              if (rule.selectorText.includes(ruleName)) {
                cssRules.push(rule);
              }
            });
        } catch (error) {
          console.error({ href: sheet.href, title: sheet.title }, error);
        }
      });
  } catch (error) {
    console.error("cssRules are unavailable in this browser");
  }

  return cssRules;
};

const setProperty = (rules, key, value) => {
  rules.forEach(rule => rule.style.setProperty(key, value, "important"));
};

export default {
  update: colors => {
    if (!colors) return;

    const color1Bg = getCSSRules(".bg.color1"),
      color1Txt = getCSSRules(".txt.color1"),
      color2Txt = getCSSRules(".txt.color2"),
      color2Bg = getCSSRules(".bg.color2"),
      color3Bg = getCSSRules(".bg.color3"),
      color4Bg = getCSSRules(".bg.color4"),
      color5Txt = getCSSRules(".txt.color5");

    setProperty(color1Bg, "background-color", colors.c1);
    setProperty(color1Txt, "color", colors.c1);
    setProperty(color2Txt, "color", colors.c2);
    setProperty(color2Bg, "background-color", colors.c2);
    setProperty(color3Bg, "background-color", colors.c3);
    setProperty(color4Bg, "background-color", colors.c4);
    setProperty(color5Txt, "color", colors.c5);
  }
};
