import React from "react";
import { Container, Icon, Header } from "semantic-ui-react";
import { maintenanceHeader, maintenanceCopy } from "../config/copy";

const MaintenancePage = () => (
  <Container text>
    <Container id="logo" text textAlign="center">
      <Icon bordered name="circle" size="huge" style={{ color: "#008000" }} />
      <Icon bordered name="circle" size="huge" style={{ color: "#FFC857" }} />
      <Icon bordered name="circle" size="huge" style={{ color: "red" }} />
    </Container>
    <Header as="h2">{maintenanceHeader}</Header>
    <div>{maintenanceCopy}</div>
  </Container>
);
export default MaintenancePage;
