import React, { Component } from "react";
import {
  Form,
  Button,
  Message,
  Header,
  Container,
  Icon
} from "semantic-ui-react";
import Field from "./Field";

class ValidFieldForm extends Component {
  state = {
    fields: []
  };

  componentDidMount() {
    this.setState({
      fields: this.props.fields.map(f => ({ ...f }))
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.fields.map(f => f.value).join() ===
      prevProps.fields.map(f => f.value).join()
    )
      return;
    this.setState({
      fields: this.props.fields.map(f => ({
        ...f,
        valid: f.checkValidity(f.value, this.props.fields)
      }))
    });
  }

  submit = e => {
    e.preventDefault();
    this.props.handleSubmit(
      this.state.fields.reduce((form, field) => {
        form[field.name] = field.value;
        return form;
      }, {})
    );
  };

  parseErrorContent(message) {
    if (typeof message === "string") return message;
    return Object.values(message).map(messages =>
      messages.map(m => <div>{m}</div>)
    );
  }

  handleFieldChange = field => (event, props) => {
    field.value = event.target.value;
    field.valid = field.checkValidity(field.value, this.state.fields, props);

    this.setState({
      fields: this.state.fields.map(f => {
        if (f.name !== field.name) return f;
        return field;
      })
    });
  };

  handleCheckBoxChange = (e, { checked }) =>
    this.setState({ privacy: checked });

  buttonIsEnabled = () =>
    this.state.fields.filter(f => !f.valid).length === 0 &&
    (this.props.checkbox ? this.state.privacy : true);

  render() {
    const {
      error,
      intro,
      headerCopy,
      buttonCopy,
      styles,
      displayStaticPage
    } = this.props;

    return (
      <div style={{ ...styles }}>
        <Container id="logo" text textAlign="center">
          <Icon
            bordered
            name="circle"
            size="huge"
            style={{ color: "#008000" }}
          />
          <Icon
            bordered
            name="circle"
            size="huge"
            style={{ color: "#FFC857" }}
          />
          <Icon bordered name="circle" size="huge" style={{ color: "red" }} />
        </Container>
        <Header as="h2">{headerCopy}</Header>
        {intro && <p dangerouslySetInnerHTML={{ __html: intro }} />}
        <Form error={!!error} onSubmit={this.submit} id="signup">
          {this.state.fields.map(field => {
            return (
              <Field
                displayStaticPage={displayStaticPage}
                handleFieldChange={this.handleFieldChange}
                field={field}
              />
            );
          })}
          {error && (
            <Message error content={this.parseErrorContent(error.message)} />
          )}
          <Button primary type="submit" disabled={!this.buttonIsEnabled()}>
            {buttonCopy}
          </Button>
        </Form>
      </div>
    );
  }
}

export default ValidFieldForm;
