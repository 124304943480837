import React, { Component } from "react";
import { Button, Modal } from "semantic-ui-react";

class Confirm extends Component {
  render() {
    const { open, loading, onConfirm, onCancel, copy } = this.props;

    return (
      <Modal size={"mini"} open={open}>
        <Modal.Header>{copy.header}</Modal.Header>
        <Modal.Content>
          <p>{copy.content}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            loading={loading}
            disabled={loading}
            onClick={onCancel}
            content={copy.negative}
          />
          <Button
            loading={loading}
            disabled={loading}
            positive
            icon="checkmark"
            labelPosition="right"
            content={copy.positive}
            onClick={onConfirm}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Confirm;
