import React from "react";
import { Segment, Item } from "semantic-ui-react";

const ResponseHistory = ({ content }) => {
  return (
    <Segment as={Item}>
      <Item.Extra>{content}</Item.Extra>
    </Segment>
  );
};

export default ResponseHistory;
