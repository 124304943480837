import React from "react";
import { Segment, Form } from "semantic-ui-react";
import "./Comment.css";

const ActionNote = ({ value, handleChange, placeholder, label, disabled }) => {
  return (
    <Segment style={{ borderTop: "none" }}>
      <Form>
        <Form.Input
          disabled={disabled}
          placeholder={placeholder}
          label={label}
          type="text"
          value={value}
          onChange={(e, { value }) => handleChange(value)}
        />
      </Form>
    </Segment>
  );
};

export default ActionNote;
