import { privacyNotice } from "./copy";

export const signUpFields = [
  {
    type: "text",
    value: "",
    name: "name",
    placeholder: "First name",
    autocomplete: "given-name",
    validityDescription: "Must be filled in",
    valid: false,
    checkValidity: value => value.length > 0
  },
  {
    type: "text",
    value: "",
    name: "surname",
    placeholder: "Surname",
    autocomplete: "family-name",
    validityDescription: "Must be filled in",
    valid: false,
    checkValidity: value => value.length > 0
  },
  {
    type: "email",
    value: "",
    name: "email",
    readonly: true,
    placeholder: "Email address",
    autocomplete: "email",
    validityDescription:
      "You can change your email address once you have registered",
    valid: false,
    checkValidity: value => /\S+@\S+\.\S+/.test(value)
  },
  {
    type: "password",
    value: "",
    name: "password",
    placeholder: "Password",
    autocomplete: "new-password",
    validityDescription: "Must be at least 8 characters",
    valid: false,
    checkValidity: value => value.length >= 8
  },
  {
    type: "password",
    value: "",
    name: "password_confirmation",
    placeholder: "Confirm password",
    autocomplete: "new-password",
    validityDescription: "Must match password",
    valid: false,
    checkValidity: (value, fields) =>
      fields[3].valid && value === fields[3].value
  },
  {
    type: "checkbox",
    value: "",
    name: "privacy",
    label: privacyNotice,
    validityDescription: "Must check box",
    valid: false,
    checkValidity: (value, fields, props = { checked: false }) => props.checked
  }
];

export const resetFields = [
  {
    type: "password",
    value: "",
    name: "password",
    placeholder: "Password",
    autocomplete: "new-password",
    validityDescription: "Must be at least 8 characters",
    valid: false,
    checkValidity: value => value.length >= 8
  },
  {
    type: "password",
    value: "",
    name: "password_confirmation",
    placeholder: "Confirm password",
    autocomplete: "new-password",
    validityDescription: "Must match password",
    valid: false,
    checkValidity: (value, fields) =>
      fields[0].valid && value === fields[0].value
  }
];

export const forgotFields = [
  {
    type: "email",
    value: "",
    name: "email",
    placeholder: "Email address",
    autocomplete: "email",
    validityDescription: "Must be a valid email address",
    valid: false,
    checkValidity: value => /\S+@\S+\.\S+/.test(value)
  }
];
